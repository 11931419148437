/* eslint-disable */
<template>
  <div id="mainGraphPane" ref="mainGraphPane">
    <SearchNode />
    <LogicalSearch :selectedNodesG6="selectedNodesG6" />
    <div class="marker">
      <a-tag v-if="Boolean($store.state.gmodule.anchor)" color="blue">
        <i class="bx bx-anchor" color="#108ee9"></i>
        {{ $store.state.gmodule.anchor }}
      </a-tag>
      <a-tag
        v-if="Boolean($store.state.gmodule.hidden_node_list.length)"
        color="blue"
      >
        Hidden Nodes: {{ $store.state.gmodule.hidden_node_list.length }}
      </a-tag>
      <a-tag v-if="selectedNodesG6.length > 0" color="blue">
        <i class="bx bx-glasses" color="#108ee9"></i>
        {{ selectedNodesG6.length }}
      </a-tag>
      <button @click="saveMyGraph" id="graph_explore_save_btn">Save</button>
    </div>
    <div class="Icons" v-for="item in inputRadioList" :key="item.id">
      <i
        v-if="item.isSelected"
        :class="`bx bx-${item.icon}`"
        class="cloud activity-icon-size"
      ></i>
    </div>
    <GroupAnalyzer
      :toggleGroupAnalyzer="isGroupAnaylzerActive"
      :selectedNodesG6="selectedNodesG6"
    />
    <div ref="mountNode">
      <div class="propsShowcase-container">
        <div v-if="propShow" class="propsShowcase">
          <h1 class="labelHeader">{{ labelHeader }}</h1>
          <div class="d-flex">
            <p class="px-2" v-for="(value, prop) in propDetail" :key="prop">
              <span class="propName">{{ prop }}</span
              >: {{ value.value }},
            </p>
          </div>
        </div>
      </div>
    </div>

    <div v-if="nodeUpdating">
      <NodeInput
        :nodeLabel="updateNode.nodeLabel"
        :nodeId="updateNode.id"
        @nodeLabelUpdate="updateNodeLabel"
        :position="updateNode.position"
      />
    </div>
    <QuickNode @createCustoNode="createCustomNodeQuick" />
    <QuickEdges
      @toggleArrow="toggleArrow"
      :isModalOpen="isEdgeModalOpen"
      @createCustomEdge="createCustomEdge"
    />
  </div>
</template>

<script>
import exploreOps from '@/common/widgets/GraphExploreWidget/exploreOps';
import {
  toolbar,
  graphContextMenu,
  minimap,
} from '@/common/widgets/GraphExploreWidget/graphPlugins';
import SearchNode from '@/common/components/SearchNode.vue';
import NodeInput from '@/common/components/NodeInput.vue';
import GraphOps from '@/common/core/Graph/graphReactor/GraphOps';
import graphReactor from '@/common/core/Graph/graphReactor';
import store from '@/store';
import { downloadData } from '@/utils/reuse';
import { mapGetters } from 'vuex';
import G6 from '@antv/g6';
// import { Minimap } from '@antv/g6';
import configs from '@/config/graphControls';
import graphConfig from '../../../config/graphConfig';
import LogicalSearch from './LogicalSearch.vue';
import GroupAnalyzer from './GroupAnalyzer.vue';
import QuickNode from './QuickNode.vue';
import QuickEdges from './QuickEdges.vue';
// import GraphInteraction from '../../../services/graphInteraction';

export default {
  components: {
    SearchNode,
    NodeInput,
    LogicalSearch,
    GroupAnalyzer,
    QuickNode,
    QuickEdges,
  },
  data() {
    return {
      isEdgeModalOpen: false,
      resolveUserInput: null,
      rejectUserInput: null,
      edgeStartNode: null,
      tempEdge: null,
      isGroupAnaylzerActive: false,
      // Rest of the data
      content: '',
      propShow: false,
      propDetail: {},
      isArrow: false,
      toggleGraph: true,
      searchWord: '',
      updateNode: {
        nodeLabel: '',
        position: {},
        id: '',
      },
      nodeUpdating: false,
      selectedNodesG6: [],
      inputRadioList: configs.inputRadioList,
    };
  },

  computed: {
    ...mapGetters({
      user: 'auth/authUser',
    }),
    getEdgeStrength() {
      return this.$store.state.edge_strength;
    },
    explore_data() {
      return this.$store.state.gmodule.g6_explore_data;
    },
    edit_mode() {
      return this.$store.state.gmodule.edit_mode;
    },
    isGenerativeFunctionLoading() {
      return this.$store.state.gmodule.generativeFunction.isActive;
    },
  },

  watch: {
    getEdgeStrength(val) {
      this.config.layout.edgeStrength = Number(val);
      // Comment
      graphReactor.exploreInstance.updateLayout({
        edgeStrength: Number(val),
      });

      // this.config.layout.edgeStrength = Number(val);
      // const controller = graphReactor.exploreInstance.get('layout');
      // controller.edgeStrength = Number(val);

      // graphReactor.exploreInstance.set('layout', controller);
      // graphReactor.exploreInstance.setAutoPaint(true);
    },
    async explore_data(val) {
      // const { predictLayout } = await GraphLayoutPredict.predict(val);
      // const layout = {
      //   type: predictLayout,
      //   nodeSize: 50,
      //   preventOverlap: true,

      console.log('write place to call it');

      /** State Maintained */

      const newHiddenNodes = this.$store.state.gmodule.hidden_node_list.map(
        (i) => {
          const availableNode = graphReactor.exploreInstance.findById(
            i.getModel().id,
          );
          console.log(`This is available node: ${availableNode}`);
          if (availableNode) {
            graphReactor.exploreInstance.hideItem(availableNode);
            return availableNode;
          }
          return i;
        },
      );
      this.$store.dispatch('gmodule/updateNewHiddenNodes', newHiddenNodes);

      /** State Less */
      // this.$store.dispatch('gmodule/updateNewHiddenNodes', []);

      // this.$store.state.gmodule.hidden_node_list.forEach((i) => {
      //   if (graphReactor.exploreInstance.findById(i.getModel().id)) {
      //     graphReactor.exploreInstance.hideItem(graphReactor.exploreInstance.findById(i.getModel().id));
      //   }
      // });
      // };
      graphReactor.exploreInstance.changeData(val);
      graphReactor.exploreInstance.updateLayout();

      this.$store.dispatch('gmodule/quickUpdateGraphData');
    },
    edit_mode(val) {
      console.log(val);
      if ((val === 'default') || (val === 'looked_at')) {
        graphReactor.exploreInstance.setMode('default');
        this.$store.state.hslState = true;
      } else if ((val === 'add_relationships')) {
        this.$store.state.hslState = false;
        // graphReactor.exploreInstance.setMode('defaultMainCreateEdge');
        graphReactor.exploreInstance.setMode('defaultMain');
      } else {
        this.$store.state.hslState = false;
        graphReactor.exploreInstance.setMode('default');
        // graphReactor.exploreInstance.setMode('defaultMain');
      }
    },
  },

  async mounted() {
    this.addedNodeCount = 0;
    await this.wikiGraphBuild();
    new ResizeObserver(this.resizeLayout).observe(this.$refs.mainGraphPane);
    window.addEventListener('keydown', this.handleKeyCombination);
  },

  async created() {
    // Add Note to Graph

    this.$root.$on('delete-selected-nodes', this.deleteSelectedNodes);

    // Delete Note from Graph

    // Delete Note from Graph
    this.$root.$on('combo-create-select', this.comboCreateSelect);

    // Delete Note from Graph
    this.$root.$on('node-create-select', this.nodeCreateSelect);
  },
  beforeDestroy() {
    this.$root.$off('combo-create-select');
    this.$root.$off('node-create-select');
    this.$root.$off('delete-selected-nodes');
  },
  methods: {
    // eslint-disable-next-line no-shadow
    afterCreateEdge(e) {
      console.log('After create edge function called.', e.edge);
      const edgess = graphReactor.exploreInstance.save().edges;
      G6.Util.processParallelEdges(edgess);
      console.log(G6.Util.processParallelEdges(edgess));
      graphReactor.exploreInstance.getEdges().forEach((edge, i) => {
        graphReactor.exploreInstance.updateItem(edge, {
          curveOffset: edgess[i].curveOffset,
          curvePosition: edgess[i].curvePosition,
        });
      });
    },
    async saveMyGraph() {
      try {
        const timestamp = Date.now();
        const humanReadableTimestamp = new Date(timestamp).toLocaleString();
        const username = this.user?.username;
        const randomGraphName = `${username}_${humanReadableTimestamp}`;

        const payloadObj = {
          graph_name: this.$store.state.gmodule.anchor
            ? this.$store.state.gmodule.anchor
            : randomGraphName,
          gdata: graphReactor.exploreInstance.save(),
          graph_type: ['Uncategorized'],
          graph_tags: 'custom',
          graph_access: 'custom',
          sharedAccess: [],
          graph_dimension: 'explore_library',
          ...(this.graphAccess === 'custom' && {
            sharedAccess: this.sharedAccess.map((i) => i.id),
          }),
        };

        const eventPayload = {
          verb: 'GRAPH_EXPLORE_SAVE',
          value: {
            title: payloadObj.graph_name,
          },
        };
        this.$store.dispatch('eventToEventStore', eventPayload);

        await this.$store.dispatch('saveCurrentGraph', payloadObj);
      } catch (error) {
        console.error('Error while saving graph: ', error);
      }
    },
    toggleArrow(val) {
      this.isArrow = val;
    },
    waitForUserInput() {
      this.isEdgeModalOpen = true;
      return new Promise((resolve, reject) => {
        this.resolveUserInput = resolve;
        this.rejectUserInput = reject;
      });
    },

    createCustomEdge(payload) {
      this.resolveUserInput(payload);
      this.isEdgeModalOpen = false;
    },

    canvasMouseMove(event) {
      const { x, y } = event;
      console.log('canvasMove');
      if (this.edgeStartNode && !this.tempEdge) {
        console.log('canvasMoveEdge', this.edgeStartNode);
        this.tempEdge = graphReactor.exploreInstance.addItem('edge', {
          source: this.edgeStartNode.getModel().id,
          target: { x, y },
          // other properties...
        });
      } else if (this.tempEdge) {
        graphReactor.exploreInstance.updateItem(this.tempEdge, {
          target: { x, y },
        });
      }
      // graphReactor.exploreInstance.updateLayout();
    },

    createCustomNodeQuick(payload) {
      payload.label
        .trim()
        .split(',')
        .forEach((keyword) => {
          exploreOps.addNodeGraph({
            label: keyword,
            tjType: payload.tjType,
            function: { additionalProperties: payload.additionalProperties, numberOfQuestions: payload.metadata.numberOfQuestions },
          });
          graphReactor.exploreInstance.updateLayout();
        });
    },

    handleKeyCombination(event) {
      // Check if 'L' key is pressed along with 'Alt' and 'Shift'
      if (event.altKey && event.shiftKey && event.code === 'KeyP') {
        event.preventDefault();
        this.isGroupAnaylzerActive = !this.isGroupAnaylzerActive;
      } else if (event.altKey && event.shiftKey && event.code === 'KeyC') {
        event.preventDefault();
        graphReactor.exploreInstance.fitView();
      } else if (event.altKey && event.shiftKey && event.code === 'KeyU') {
        event.preventDefault();
        store.dispatch('setQuestUploadingStatus');
      } else if (event.altKey && event.shiftKey && event.code === 'KeyD') {
        event.preventDefault();
        downloadData(graphReactor.exploreInstance.save());
      } else if (event.altKey && event.shiftKey && event.code === 'KeyM') {
        event.preventDefault();
        this.$store.dispatch('gmodule/updateAnchor');
      } else if (event.altKey && event.shiftKey && event.code === 'KeyR') {
        event.preventDefault();
        const nodes = graphReactor.exploreInstance.findAllByState(
          'node',
          'selected',
        );
        this.$store.dispatch('gmodule/formRelationshipUsingOpenAI', nodes);
        this.$store.dispatch('gmodule/saveG6ExploreData');
      }
    },
    nodeCreateSelect(payload) {
      exploreOps.nodeCreateSelect(
        payload,
        this.selectedNodesG6,
        this.$store.state.gmodule.isArrowEdge,
      );
      this.$store.dispatch('gmodule/saveG6ExploreData');
    },

    resizeLayout() {
      // console.log('resizeLayout', this.$refs.mainGraphPane.clientHeight, this.$refs.mainGraphPane.clientWidth);
      graphReactor.exploreInstance.changeSize(
        this.$refs.mainGraphPane.clientWidth,
        this.$refs.mainGraphPane.clientHeight,
      );
      graphReactor.exploreInstance.layout();
    },
    comboCreateSelect(label) {
      console.log({ selected: this.selectedNodesG6 });
      exploreOps.comboCreateSelect(label, this.selectedNodesG6);
      this.$store.dispatch('gmodule/saveG6ExploreData');
    },

    uniqueIdGenerate() {
      // Generate random id for node
      return Math.random().toString(36).substr(2, 9);
    },

    // Delete Selected Nodes
    deleteSelectedNodes() {
      exploreOps.deleteSelectedNodes(this.selectedNodesG6);
    },

    nodeSelectChange(e) {
      // console.log('GraphEngine: GraphExplore -> ', e);
      if (e.selectedItems.nodes.length > 1) {
        this.$store.dispatch('gmodule/toggleGroupMode', e.select);
      }
      console.log(e);
      if (this.$store.state.gmodule.edit_mode === 'show_related_nodes') {
        store.dispatch('gmodule/hiddenNodesList', [...e.selectedItems.nodes]);
        [...e.selectedItems.nodes].forEach((node) => graphReactor.exploreInstance.hideItem(node));
      }

      this.selectedNodesG6 = e.selectedItems.nodes;
    },

    videoEmbedURLFromURL(url) {
      if (url) {
        const regex = /^https?:\/\/(?:www\.)?(?:youtube\.com\/(?:embed\/|v\/|watch\?v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})(?:\S+)?$/;
        const match = url.match(regex);
        if (match && match[1].length === 11) {
          return `https://www.youtube.com/embed/${match[1]}?rel=0`;
        }
      }
      return '';
      // ^.*(youtu.be\/|v\/|embed\/|watch\?|youtube.com\/user\/[^#]*#([^\/]*?\/)*)\??v?=?([^#\&\?]*).*
    },

    updateNodeLabel(payload) {
      const updatedNode = graphReactor.exploreInstance.findById(payload.id);
      updatedNode.update({
        label: payload.labelName,
      });
      this.nodeUpdating = false;
    },

    async changeGraph(searchWord) {
      await this.$store
        .dispatch('gmodule/exploreGraphFetch', searchWord)
        .then(() => {
          graphReactor.exploreInstance.changeData(
            this.$store.state.gmodule.g6_explore_data,
          );
        });
    },

    async wikiGraphBuild() {
      this.config = graphConfig;
      this.config.container = this.$refs.mountNode;
      this.config.plugins = [toolbar, graphContextMenu, minimap];
      this.config.width = this.$refs.mainGraphPane.scrollWidth;
      this.config.height = this.$refs.mainGraphPane.scrollHeight;

      GraphOps.GraphInitializer(this.config, 'Explore');

      GraphOps.eventListener('canvas:click', 'Explore', this.canvasClick);

      GraphOps.eventListener('combo:click', 'Explore', this.comboClick);

      // Click Event
      GraphOps.eventListener('node:click', 'Explore', this.nodeClick);

      GraphOps.eventListener('node:dblclick', 'Explore', this.nodeDblClick);

      GraphOps.eventListener('aftercreateedge', 'Explore', this.afterCreateEdge);

      GraphOps.eventListener(
        'nodeselectchange',
        'Explore',
        this.nodeSelectChange,
      );

      GraphOps.eventListener('node:mouseenter', 'Explore', (e) => {
        const hoveredNode = e.item;
        const hoveredNodeId = hoveredNode.getID();

        if (this.$store.state.hslState) {
          const graph = graphReactor.exploreInstance;

          // Get the hovered node and its ID

          // Set the hover state for the hovered node
          graph.setItemState(hoveredNode, 'hover', true);

          // Get neighbors of the hovered node
          const neighbors = graph.getNeighbors(hoveredNodeId);
          neighbors.push(hoveredNode);

          // Set label and edge opacity for all nodes and edges
          graph.getNodes().forEach((node) => {
            const opacity = neighbors.includes(node) ? 1 : 0.1;

            graph.updateItem(node, {
              labelCfg: {
                style: {
                  opacity,
                },
              },
            });

            // Set edge opacity for connected edges
            node.getEdges().forEach((edge) => {
              const targetNode = edge.getTarget();
              const sourceNode = edge.getSource();

              if (targetNode === hoveredNode || sourceNode === hoveredNode) {
                graph.updateItem(edge, {
                  labelCfg: {
                    style: {
                      opacity: 1,
                    },
                  },
                });
              } else {
                // Set opacity for other edges
                graph.updateItem(edge, {
                  labelCfg: {
                    style: {
                      opacity: 0,
                    },
                  },
                });
              }
            });
          });
        } else {
          const graph = graphReactor.exploreInstance;
          graph.setItemState(hoveredNode, 'hover', true);

          // Get neighbors of the hovered node
          const neighbors = graph.getNeighbors(hoveredNodeId);
          neighbors.push(hoveredNode);

          // Set label and edge opacity for all nodes and edges
          graph.getNodes().forEach((node) => {
            // const nodeOpacity = node.getModel().labelCfg.style.opacity;
            const isFocusEd = node.hasState('focusEd');
            const isSelectEd = node.hasState('selectEd');
            // const nodeLabel = node.getModel().label;
            // console.log(isFocusEd, isSelectEd, nodeLabel);
            if (isFocusEd && isSelectEd) {
              this.dynamicOpacity = 1;
            } else {
              this.dynamicOpacity = neighbors.includes(node) ? 1 : 0.1;
            }
            graph.updateItem(node, {
              labelCfg: {
                style: {
                  opacity: this.dynamicOpacity,
                },
              },
            });

            // Set edge opacity for connected edges
            node.getEdges().forEach((edge) => {
              const targetNode = edge.getTarget();
              const sourceNode = edge.getSource();

              if (targetNode === hoveredNode || sourceNode === hoveredNode) {
                graph.updateItem(edge, {
                  labelCfg: {
                    style: {
                      opacity: 1,
                    },
                  },
                });
              } else {
                // Set opacity for other edges
                graph.updateItem(edge, {
                  labelCfg: {
                    style: {
                      opacity: 0,
                    },
                  },
                });
              }
            });
          });
        }
        this.propDetail = hoveredNode.getModel().props;
        this.labelHeader = hoveredNode.getModel().label;
        this.propDetail = e.item.getModel().props;
        this.propShow = true;
      });

      GraphOps.eventListener('node:mouseleave', 'Explore', () => {
        // Reset the properties
        this.propShow = false;
        this.propDetail = {};
        this.labelHeader = {};
        if (this.$store.state.hslState) {
          const graph = graphReactor.exploreInstance;

          // Get all nodes
          const allNodes = graph.getNodes();

          // Revert styles for all nodes
          allNodes.forEach((node) => {
            const edges = node.getEdges();
            edges.forEach((edge) => {
              graph.updateItem(edge, {
                labelCfg: {
                  style: {
                    opacity: 1,
                  },
                },
              });
            });
            // Revert label color
            graph.updateItem(node, {
              labelCfg: {
                style: {
                  fill: '#FBFAF5', // Replace with the normal label color
                  opacity: 1, // Replace with the normal label opacity
                },
              },
            });

            // Revert opacity for all nodes
            graph.updateItem(node, {
              style: {
                opacity: 1, // Replace with the normal node opacity
              },
            });
          });
        } else {
          const graph = graphReactor.exploreInstance;
          graph.getNodes().forEach((otherNode) => {
            const isHighlight = otherNode.hasState('focusEd') || otherNode.hasState('relatedEd');
            // const isHighlightEdge = otherNode.hasState('relatedEd');
            const opacity = isHighlight ? 1 : 0.1;
            // const opacityEdge = isHighlightEdge ? 1 : 0.1;

            graph.updateItem(otherNode, {
              labelCfg: {
                style: {
                  opacity,
                },
              },
              style: {
                opacity,
              },
            });

            const edges = otherNode.getEdges();
            edges.forEach((edge) => {
              const isHighlightEdge = edge.hasState('relatedEd');
              //   // const opacity = isHighlight ? 1 : 0.1;
              const opacityEdge = isHighlightEdge ? 1 : 0.1;
              graph.updateItem(edge, {
                labelCfg: {
                  style: {
                    opacity: opacityEdge,
                  },
                },
              });
            });

            // Adjust opacity for connected edges
            // otherNode.getEdges().forEach((edge) => {
            //   const isHighlightEdge = otherNode.hasState('relatedEd');
            //   // const opacity = isHighlight ? 1 : 0.1;
            //   const opacityEdge = isHighlightEdge ? 1 : 1;

            //   graph.updateItem(edge, {
            //     labelCfg: {
            //       style: {
            //         opacityEdge,
            //       },
            //     },
            //   });
            // });
          });
        }
        this.propShow = false;
        this.propDetail = {};
      });

      GraphOps.eventListener('mousemove', 'Explore', this.exploreMouseMove);

      GraphOps.eventListener('edge:click', 'Explore', this.edgeClick);

      GraphOps.eventListener('edge:mouseenter', 'Explore', this.edgeEnter);

      GraphOps.eventListener('edge:mouseleave', 'Explore', this.edgeLeave);
      // GraphOps.eventListener('edge:click', 'Explore', this.edgeClick);

      GraphOps.eventListener('node:dragstart', 'Explore', this.nodeDragStart);

      GraphOps.eventListener('node:drag', 'Explore', this.nodeDrag);
      // GraphOps.eventListener('node:dragend', 'Explore', this.nodeDragEnd);

      // graphReactor.exploreInstance.data(
      //   this.$store.state.gmodule.g6_explore_data,
      // );
      GraphOps.graphData('Explore', this.$store.state.gmodule.g6_explore_data);
      // graphReactor.exploreInstance.render();
      GraphOps.graphOperation('render', 'Explore');

      GraphOps.graphOperation('updateLayout', 'Explore', {
        type: 'gForce',
      });

      // document.querySelector('.g6').onresize = () => {

      //   const myCanvas = this.$refs.mountNode;

      //   // if (!graphReactor.exploreInstance || graphReactor.exploreInstance.get('destroyed')) return;
      //   // if (!container || !container.scrollWidth || !container.scrollHeight) return;
      //   graphReactor.exploreInstance.changeSize(myCanvas.clientWidth, 708);
      //   // graphReactor.exploreInstance.fitCenter();
      // };
    },

    // Wiki Graph Build Operations:
    // Node Drag End
    // nodeDragEnd() {
    // graphReactor.exploreInstance.updateViewport({
    //   minX: graphReactor.exploreInstance.get('minX') - 1,
    //   minY: graphReactor.exploreInstance.get('minY') - 1,
    // });
    // graphReactor.exploreInstance.updateLayout();
    // },

    // Canvas Click Operation
    canvasClick(ev) {
      this.$store.state.hslState = true;
      this.$store.dispatch('gmodule/graphCanvasClick', ev);
      this.nodeUpdating = false;
    },

    // Combo Click Operation
    comboClick(e) {
      const comboItem = e.item;
      const comboModel = comboItem.getModel();
      if (this.$store.state.gmodule.edit_mode === 'combo_explore') {
        this.$store.dispatch('gmodule/exploreThoughtMap', comboModel.tjData);
      } else if (
        this.$store.state.gmodule.edit_mode === 'combo_expand_collapse'
      ) {
        graphReactor.exploreInstance.collapseExpandCombo(comboItem);
      } else if (this.$store.state.gmodule.edit_mode === 'remove') {
        graphReactor.exploreInstance.uncombo(comboItem);
      }
    },

    // Node Click Operation
    async nodeClick(e) {
      if (
        (e.originalEvent.ctrlKey || e.originalEvent.altKey)
        && !this.edgeStartNode
      ) {
        // Check if Ctrl is pressed
        this.edgeStartNode = e.item;
        graphReactor.exploreInstance.on(
          'canvas:mousemove',
          this.canvasMouseMove,
        );
      }
      if (
        this.edgeStartNode
        && this.edgeStartNode !== e.item
        && (e.originalEvent.ctrlKey || e.originalEvent.altKey)
      ) {
        graphReactor.exploreInstance.removeItem(this.tempEdge);
        const label = await this.waitForUserInput();
        graphReactor.exploreInstance.addItem('edge', {
          source: this.edgeStartNode.getModel().id,
          target: e.item.getModel().id,
          label,
          style: {
            endArrow: this.isArrow,
          },
          // other properties...
        });
        this.edgeStartNode = null;
        this.tempEdge = null;
        console.log('it should have been removed');
        graphReactor.exploreInstance.off(
          'canvas:mousemove',
          this.canvasMouseMove,
        );
        // Open your label input popup here...
        // Pass evt.item as the target node for the new edge
      }
      // if (this.edgeStartNode) {
      //   graphReactor.exploreInstance.addItem('edge', {
      //     source: this.edgeStartNode.getModel().id,
      //     target: e.item.getModel().id,
      //     label: 'test',
      //     // other properties...
      //   });
      //   this.edgeStartNode = null;
      // }
      if (!(e.originalEvent.ctrlKey || e.originalEvent.altKey)) {
        this.$store.dispatch('gmodule/graphNodeClick', {
          graphName: 'explore',
          event: e,
        });
        graphReactor.exploreInstance.updateLayout();
      }
    },

    // Node Double Click
    nodeDblClick(e) {
      e.originalEvent.preventDefault();
      if (this.$store.state.gmodule.edit_mode === 'looked_at') {
        const nodeItem = e.item;
        const nodeModel = nodeItem.getModel();
        const nodeId = nodeModel.id;

        this.updateNode.nodeLabel = nodeModel.label;
        this.updateNode.position = {
          x: e.clientX,
          y: e.clientY,
        };
        this.updateNode.id = nodeId;
        this.nodeUpdating = true;
      }
    },

    // G6 Explore mouse move
    exploreMouseMove(e) {
      this.$store.dispatch('gmodule/graphMouseMove', {
        graphName: 'explore',
        event: e,
      });
      // graphReactor.exploreInstance.updateLayout();
    },

    // click on the edge
    edgeClick(e) {
      this.$store.dispatch('gmodule/graphEdgeClick', e);
    },
    edgeEnter(e) {
      graphReactor.exploreInstance.setItemState(e.item, 'hover', true);
    },
    edgeLeave(e) {
      graphReactor.exploreInstance.setItemState(e.item, 'hover', false);
    },

    nodeDragStart() {
      const forceLayout = graphReactor.exploreInstance.get('layoutController').layoutMethods[0];
      forceLayout.stop();
      // graphReactor.exploreInstance.updateLayout();
    },

    nodeDrag(e) {
      this.refreshDragedNodePosition(e);
      graphReactor.exploreInstance.layout();
    },

    // nodeDragEnd() {
    //   // const forceLayout = graphReactor.exploreInstance.get('layoutController').layoutMethods[0];
    //   // forceLayout.start();
    //   console.log('xvf', 'drag ends here');
    //   // graphReactor.exploreInstance.updateLayout();
    // },

    refreshDragedNodePosition(e) {
      const model = e.item.get('model');
      model.fx = e.x;
      model.fy = e.y;
      model.x = e.x;
      model.y = e.y;
      // graphReactor.exploreInstance.updateLayout();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/abstracts/variables";

div {
  font-family: "Helvetica", sans-serif;
}

#graph_explore_save_btn {
  // position: absolute;
  // top: 10px;
  // right: 10px;
  // z-index: 100;
  opacity: 0.5;
  border-radius: 5px;
  border: none;
  transition: all 0.3s ease-out;
  &:hover {
    opacity: 1;
    background: #010148;
    color: #fff;
  }
}

#graph_explore_clear_btn {
  margin-right: 5px;
  opacity: 0.5;
  border-radius: 5px;
  border: none;
  transition: all 0.3s ease-out;
  &:hover {
    opacity: 1;
    background: #010148;
    color: #fff;
  }
}

.generative-function-loader {
  position: absolute;
  top: 25px;
  right: 15px;
}

#viewnode {
  padding: 0.9375em 0.9375em;
}

.marker {
  margin-top: 10px;
  right: 10px;
  position: absolute;
}

.selected_nodes {
  right: 30px;
}

.propsShowcase {
  pointer-events: none !important;

  position: absolute;
  bottom: 0;
  width: 100%;
  font-family: "Poppins", sans-serif;
  padding: 7px 10px 7px 20px;
  color: #fff;
  border-radius: 0px 0px 10px 10px !important;
  backdrop-filter: blur(14px);
  box-shadow: rgba(255, 255, 255, 0.16) 0px 3px 6px,
    rgba(255, 255, 255, 0.23) 0px 3px 6px;
  .propName {
    color: #fdc400;
  }
}
.labelHeader {
  margin-left: 5px;
  font-size: 15px;
  padding: 7px 20px 7px 7px;
  color: #fdc400;
  // // text-align: center;
  pointer-events: none;
  user-select: none;
}
.label-container {
  width: 100%;
  position: absolute;
  bottom: 0;
  pointer-events: none;
  user-select: none;
}

.Icons {
  position: absolute;
  top: 5rem;
  left: 1.5rem;
  color: #e2e2e2;
}
.cloud {
  backdrop-filter: blur(4px);
  padding: 5px;
  border-radius: 50%;
}
.activity-icon-size {
  transform: scale(1.5);
}
</style>
