<template>
  <div v-if="showSearch" class="search-backdrop__container">
    <div
      class="search-backdrop"
      style="
        min-width: 50%;
        background-color: #fff;
        padding: 15px;
        border-radius: 4px;
      "
    >
      <div class="search-backdrop__row">
        <p>Node Label:</p>
        <a-input
          style="height: 50px"
          v-model="nodeLabel"
          @pressEnter="search"
          ref="labelInput"
          placeholder="Node Label..."
        ></a-input>
      </div>
      <div class="search-backdrop__row">
        <p>Node Type:</p>
        <a-select v-model="nodeType" style="width: 120px">
          <a-select-option
            v-for="option in nodeTypeOptions"
            :key="option"
            :value="option"
          >
            {{ option }}
          </a-select-option>
        </a-select>
      </div>
      <div class="search-backdrop__row" v-if="nodeType === 'function'">
        <p>Additional Properties</p>
        <a-input
          style="height: 50px"
          v-model="additionalProperties"
          @pressEnter="search"
          ref="properties"
          placeholder="Optional"
        ></a-input>
      </div>
      <div class="search-backdrop__row" v-if="nodeType === 'function' && roles[0] !== 'BootcampFall23'">
          <p>Number of Questions: {{ numberOfQuestions }}</p>
          <input
            type="range"
            min="3"
            max="10"
            v-model="numberOfQuestions"
            class="form-range ed-slider"
            id="nodeSize"
          />
        </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      showSearch: false,
      nodeLabel: '',
      randomTitle: '',
      nodeType: 'custom',
      additionalProperties: 'instance-of, type, quick summary',
      nodeTypeOptions: ['custom-node', 'openai', 'function'],
      numberOfQuestions: 3,
    };
  },

  mounted() {
    window.addEventListener('keydown', (event) => {
      // Check if Ctrl (or Cmd in Mac) and S keys are pressed together
      if (event.altKey && event.shiftKey && event.code === 'KeyN') {
        // Prevent the default behavior (in this case, saving the page)
        event.preventDefault();
        // Optionally, you can provide your custom functionality here
        // eslint-disable-next-line no-alert
        if (!this.showSearch) {
          this.enableQuickSearch();
          this.$nextTick(() => {
            this.$refs.labelInput.focus();
          });
        } else {
          this.disableQuickSearch();
        }
      }

      if (event.altKey && event.shiftKey && event.code === 'KeyF') {
        // Prevent the default behavior (in this case, saving the page)
        event.preventDefault();
        // Optionally, you can provide your custom functionality here
        // eslint-disable-next-line no-alert
        this.nodeType = 'function';
        if (!this.showSearch) {
          this.enableQuickSearch();
          this.$nextTick(() => {
            this.$refs.labelInput.focus();
          });
        } else {
          this.disableQuickSearch();
        }
      }

      if (event.code === 'Escape') {
        this.$store.dispatch('gmodule/setEditMode', 'looked_at');
        this.disableQuickSearch();
      }
    });
  },

  computed: {
    ...mapGetters({
      user: 'auth/authUser',
    }),

    roles() {
      return this.user.roles;
    },
  },

  methods: {
    search() {
      this.disableQuickSearch();
      // node create functionn
      this.$emit('createCustoNode', {
        label: this.nodeLabel,
        tjType: this.nodeType,
        additionalProperties: this.additionalProperties, // instance_of, type
        metadata: {
          numberOfQuestions: this.numberOfQuestions,
        },
      });
      this.nodeLabel = '';
    },
    disableQuickSearch() {
      this.showSearch = false;
    },
    enableQuickSearch() {
      this.showSearch = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.search-backdrop {
  &__container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7); // Darkened backdrop
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }

  &__row {
    margin: 0.5rem;
  }

  // > div {
  //   box-shadow: 0px 6px 15px rgba(1, 1, 20, 0.15); // A subtle shadow
  //   border: 1px solid #01014d; // Using the primary dark color
  // }

  p {
    color: #01014d; // Using the primary dark color
    font-weight: bold; // Making the title more pronounced
    margin-bottom: 15px; // A bit of space before the input
  }

  .ant-input {
    height: 50px;
    border-radius: 4px; // Slightly rounded corners
    border: 1px solid #01014d; // Using the primary dark color for the border
    transition: all 0.3s ease-in-out;

    &:hover,
    &:focus {
      border-color: #004cfd; // Highlight with yellow on hover/focus
      box-shadow: 0 0 10px rgba(0, 89, 253, 0.4); // Subtle yellow glow
    }

    &::placeholder {
      color: #01014d; // Using the primary dark color for placeholder text
      opacity: 0.7; // A bit transparent for better visual hierarchy
    }
  }
}
</style>
