<template>
  <div id="node_input">
    <form @submit.prevent="nodeLabelUpdate">
      <input id="uniqueNodeInput" v-model="nodeLabel" :style="dynamicPositionStyle(position)" placeholder="update node label" />
    </form>
  </div>
</template>

<script>
export default {
  props: {
    nodeLabel: {
      type: String,
      required: true,
    },
    position: {
      type: Object,
      required: true,
    },
    nodeId: {
      type: String,
      required: true,
    },
  },

  methods: {
    dynamicPositionStyle(position) {
      return {
        position: 'fixed',
        left: `${position.x}px`,
        top: `${position.y}px`,
      };
    },
    nodeLabelUpdate() {
      console.log('this id: ', this.nodeId);
      this.$emit('nodeLabelUpdate', { id: this.nodeId, labelName: this.nodeLabel });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/abstracts/variables";

#node_input {
  #uniqueNodeInput {
    border: none;
    padding: 0.5em 1em;
    min-width: 100px;
    border-radius: 5px;
    font-family: $fontFamily;
    border: 2px solid $edvantaBlue;
    &:focus {
      outline: none;
    }
  }
}
</style>
