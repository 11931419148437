<template>
    <div v-if="showSearch" class="search-backdrop">
      <div
        style="width: 40%; background-color: #fff; padding: 15px; border-radius: 4px"
      >
      <div style="display: flex; justify-content: space-between;">
        <p>Edge Label:</p>
        <i
          name="right-arrow-alt"
          @click.prevent="toggleArrow"
          title="Add arrow"
          :class="isArrow ? 'box-icon--active' : ''"
          class="box-icon box-icon--effect bx bx-right-arrow-alt"
        ></i>
      </div>
        <a-input
            style="height: 50px"
            v-model="edgeLabel"
            @pressEnter="search"
            ref="edgeInput"
            placeholder="Edge Label..."
            @click.stop
        ></a-input>
      </div>
    </div>
</template>

<script>
export default {
  props: ['isModalOpen'],
  data() {
    return {
      isArrow: false,
      showSearch: false,
      edgeLabel: '',
      randomTitle: '',
    };
  },

  watch: {
    isModalOpen(val) {
      this.showSearch = val;
    },
    showSearch(newVal) {
      if (newVal) {
        this.$nextTick(() => {
          this.$refs.edgeInput.focus();
        });
      }
    },
  },

  mounted() {
    this.showSearch = this.isModalOpen;
  },

  methods: {
    toggleArrow(e) {
      e.preventDefault();
      this.isArrow = !this.isArrow;
      this.$emit('toggleArrow', this.isArrow);
    },

    search() {
      this.disableQuickSearch();
      // node create functionn
      this.$emit('createCustomEdge', this.edgeLabel);
      this.edgeLabel = '';
    },
    disableQuickSearch() {
      this.showSearch = false;
    },
    enableQuickSearch() {
      this.showSearch = true;
    },
  },

};
</script>

<style lang="scss" scoped>

.search-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); // Darkened backdrop
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  > div {
    box-shadow: 0px 6px 15px rgba(1, 1, 20, 0.15); // A subtle shadow
    border: 1px solid #01014D; // Using the primary dark color
  }

  p {
    color: #01014D; // Using the primary dark color
    font-weight: bold; // Making the title more pronounced
    margin-bottom: 15px; // A bit of space before the input
  }

  .ant-input {
    height: 50px;
    border-radius: 4px; // Slightly rounded corners
    border: 1px solid #01014D; // Using the primary dark color for the border
    transition: all 0.3s ease-in-out;

    &:hover,
    &:focus {
      border-color: #004cfd; // Highlight with yellow on hover/focus
      box-shadow: 0 0 10px rgba(0, 89, 253, 0.4); // Subtle yellow glow
    }

    &::placeholder {
      color: #01014D; // Using the primary dark color for placeholder text
      opacity: 0.7; // A bit transparent for better visual hierarchy
    }
  }
}

</style>
