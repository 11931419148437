export default {
  /**
   * Categories available to
   * save graphs you can add
   * more to them
   */
  categories: [
    'Science',
    'Technology & Gadgets',
    'Entertainment & Media',
    'Business',
    'Games & Sports',
    'Art & Culture',
    'Educational',
    'Travel - Holidays, Places',
    'Food & Drinks',
    'History & Events',
    'Finances',
    'Health & Wellbeing',
    'Uncategorized',
  ],
  /**
   * List for available controllers
   * Graph Controls you can change
   * icons and label of that from here eaisly
   */
  inputRadioList: [
    {
      id: 'looked_at',
      label: 'Node focus',
      icon: 'pointer',
      isSelected: true,
      linkedAbility: 'EditNodeDetials',
    },
    {
      id: 'explore',
      label: 'Explore',
      icon: 'search-alt',
      isSelected: false,
      linkedAbility: 'ExporeInGraph',
    },
    {
      id: 'add_nodes',
      label: 'Add Nodes',
      icon: 'add-to-queue',
      isSelected: false,
      linkedAbility: 'CreateNode',
    },
    {
      id: 'add_relationships',
      label: 'Add RelationShips',
      icon: 'shape-polygon',
      isSelected: false,
      linkedAbility: 'AddRelationships',
    },
    {
      id: 'remove',
      label: 'Remove',
      icon: 'x-circle',
      isSelected: false,
      linkedAbility: 'RemoveNodes',
    },
    {
      id: 'hsl',
      label: 'Highlight and Select',
      icon: 'selection',
      isSelected: false,
    },
    {
      id: 'combo_explore',
      label: 'Fetch thoughtmap',
      icon: 'cloud-drizzle',
      isSelected: false,
    },
    {
      id: 'combo_expand_collapse',
      label: 'Expand/Collapse thoughtmap',
      icon: 'expand',
      isSelected: false,
    },
    {
      id: 'hide_related_nodes',
      label: 'Hide Related Nodes',
      icon: 'hide',
      isSelected: false,
      linkedAbility: 'RelatedShowHide',
    },
    {
      id: 'show_related_nodes',
      label: 'Select nodes to hide',
      icon: 'paint-roll',
      isSelected: false,
      linkedAbility: 'RelatedShowHide',
    },
    {
      id: 'hide',
      label: 'Hide',
      icon: 'ghost',
      isSelected: false,
      linkedAbility: 'Hide',
    },
    {
      id: 'lock',
      label: 'Lock',
      icon: 'lock',
      isSelected: false,
      linkedAbility: 'Lock',
    },
    {
      id: 'shortest_path',
      label: 'Shortest Path',
      icon: 'trip',
      isSelected: false,
      linkedAbility: 'ShortestPath',
    },
    {
      id: 'function_call',
      label: 'Trigger Function',
      icon: 'color',
      isSelected: false,
      linkedAbility: 'triggerSelectedFunctions',
    },
    {
      id: 'clear_graph',
      label: 'Clear canvas',
      icon: 'brush-alt',
      isSelected: false,
      linkedAbility: 'ClearCanvas',
    },
  ],
};
