<template>
    <div>
        <edModal
            :confirmLoading="isGroupNodeLoading"
            :visible="isGroupNodeVisible"
            @handleOk="groupNodeProcessWithOpenAI"
            @cancelModal="cancelGroupNodeModal"
        >
        <template #dynamic-content>
          <div v-if="!Boolean(selectedNodesG6.length)">
            <a-result status="warning" title="There are some problems with your operation.">
              <template #extra>
                Please select some nodes and try again
              </template>
            </a-result>
          </div>

            <a-tabs :default-active-key="activeTabKey" @change="callback" v-else>
              <a-tab-pane key="2" tab="Add Properties(AI)" force-render>
                <div>
                  <a-textarea
                    v-model="propertiesText"
                    placeholder="Add properties, e.g. instance, type, etc..."
                    :auto-size="{ minRows: 3, maxRows: 5 }"
                    @input="validateTextarea"
                    @keyup.enter="triggerHandleOk"
                  />
                </div>
              </a-tab-pane>
              <a-tab-pane key="1" tab="Summarize Nodes">
                <div >
                    <a-text>Query:</a-text>
                    <a-input v-model="groupNodeQuestion" placeholder="e.g. Give me detail comparision among these nodes." />
                    <br>
                    <br>
                    <a-text>Node Name:</a-text>
                    <a-input v-model="groupNodeLabel" placeholder="e.g. Summarization" />
                    <br>
                    <br>
                    <a-text>Edge Name:</a-text>
                    <a-input v-model="groupNodeEdgeLabel" placeholder="e.g. of" />
                    <br>
                    <br>
                </div>
              </a-tab-pane>

            </a-tabs>
        </template>
        </edModal>

    </div>
</template>

<script>
import edModal from '@/common/components/ed-forms/ed-modal.vue';
import swal from 'sweetalert';
import chunkify from '../../../utils/chunkify';

export default {
  props: ['selectedNodesG6', 'toggleGroupAnalyzer'],
  data() {
    return {
      // Related to Group Nodes Summarization uisign openai
      isGroupNodeLoading: false,
      isGroupNodeVisible: false,
      groupNodeQuestion: '',
      groupNodeLabel: '',
      groupNodeEdgeLabel: '',

      activeTabKey: '2',
      propertiesText: '',
    };
  },
  components: {
    edModal,
  },
  watch: {
    toggleGroupAnalyzer(val) {
      this.isGroupNodeVisible = val;
    },
  },

  mounted() {
    this.isGroupNodeVisible = this.toggleGroupAnalyzer;
    this.$root.$on('process-selected-nodes-openai', this.processNodesUsingOpenai);
  },

  beforeDestroy() {
    this.$root.$off('process-selected-nodes-openai');
  },
  methods: {
    callback(key) {
      this.activeTabKey = key;
    },
    async groupNodeProcessWithOpenAI() {
      this.isGroupNodeLoading = true;

      if (this.activeTabKey === '1') {
        await this.$store.dispatch('gmodule/groupNodeProcessWithOpenAI', {
          selectedNodes: this.selectedNodesG6,
          query: this.groupNodeQuestion,
          nodeLabel: this.groupNodeLabel,
          edgeLabel: this.groupNodeEdgeLabel,
        });
      } else if (this.activeTabKey === '2') {
        console.log('this one has been called');
        if (this.selectedNodesG6.length > 99) {
          swal({
            title: 'Limit Reached',
            text: 'Oops! A maximum of 99 nodes can be selected at a time for this operation.',
            icon: 'warning',
          });
        } else {
          const chunks = chunkify(this.selectedNodesG6);
          console.log(chunks);
          await Promise.all(chunks.map((nodeList) => this.$store.dispatch('gmodule/groupedPropertiesWithOpenAI', {
            selectedNodes: nodeList,
            properties: this.propertiesText,
          }).catch((error) => {
            console.error('Error in request:', error);
          // Handle individual errors if needed
          })))
            .finally(() => {
            // Close your loading indicator here
            });
        }
        // await this.$store.dispatch('gmodule/groupedPropertiesWithOpenAI', {
        //   selectedNodes: this.selectedNodesG6,
        //   properties: this.propertiesText,
        // });
      }

      this.isGroupNodeLoading = false;
      this.isGroupNodeVisible = false;
    },

    async processNodesUsingOpenai() {
      console.log(this.selectedNodesG6);
      this.isGroupNodeVisible = true;
    },

    cancelGroupNodeModal() {
      this.isGroupNodeVisible = false;
    },

    triggerHandleOk() {
      // Trigger the groupNodeProcessWithOpenAI method when Enter key is pressed
      this.groupNodeProcessWithOpenAI();
    },

  },
};
</script>

<style lang="scss" scoped>

</style>
