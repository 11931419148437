<template>
  <div>
    <a-modal
      :title="modalTitle"
      :visible="visible"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
      centered
      @cancel="handleCancel"
    >
        <form @submit.prevent="handleOk">
          <slot name="dynamic-content"></slot>
        </form>
    </a-modal>
  </div>
</template>
<script>
export default {
  props: {
    modalTitle: String,
    confirmLoading: Boolean,
    visible: Boolean,
  },
  methods: {
    showModal() {
      this.$emit('showModal', true);
    },
    handleOk() {
      this.$emit('handleOk', this.searchQuery);
    },
    handleCancel() {
      console.log('Clicked cancel button');
      this.$emit('cancelModal');
    },
  },
};
</script>
