<template>
  <form class="d-flex mx-5" @submit.prevent>
    <vue-simple-suggest
      type="search"
      :placeholder="$t('comp_graph--searchPlaceholder')"
      aria-label="Search"
      v-model.lazy="searchWord"
      :list="newList"
      :debounce="200"
      :controls="{
        selectionUp: [38, 33],
        selectionDown: [40, 34],
        select: [13, 36],
        showList: [40],
        hideList: [27, 35],
      }"
      :nullable-select="true"
      ref="searchNode"
      value-attribute="id"
      display-attribute="text"
      @suggestion-click="onSuggestClick"
      class="input-wrap node-search"
    ></vue-simple-suggest>
  </form>
</template>

<script>
import VueSimpleSuggest from 'vue-simple-suggest';
import 'vue-simple-suggest/dist/styles.css';
import exploreOps from '@/common/widgets/GraphExploreWidget/exploreOps';

export default {
  data() {
    return {
      searchWord: '',
      newList: [],
    };
  },

  watch: {
    searchWord(val) {
      this.newList = this.$store.state.gmodule.g6_explore_data.nodes
        .filter((node) => node.label.toLowerCase().includes(val.toLowerCase()))
        .map((node) => node.label);
      if (val !== '') {
        exploreOps.nodeSearchFocus(this.newList);
      } else if (val === '') {
        exploreOps.nodeSearchClear();
      }
    },
  },

  computed: {
    explore_data() {
      return this.$store.state.gmodule.g6_explore_data;
    },
  },

  components: {
    VueSimpleSuggest,
  },

  methods: {
    onSuggestClick(val) {
      setTimeout(() => {
        exploreOps.nodeSearch(val);
        this.$store.dispatch('setCurrentThought', {
          thoughtObject: {
            word: val,
            label: val,
            type: 'string',
            lang: 'en',
          },
          excludeCallList: ['gmodule/exploreGraphFetch'],
        });
      }, 100);
    },
    // onSuggestSelect(val) {

    //   this.$root.$emit('node-search', val);
    // },
    // fetchInformation() {

    // },
    handleKeyCombination(event) {
      if (event.altKey && event.shiftKey && event.code === 'KeyS') {
        event.preventDefault();
        const inputField = this.$refs.searchNode.input;
        if (inputField) {
          inputField.focus();
        }
      }
    },

  },
  async mounted() {
    window.addEventListener('keydown', this.handleKeyCombination);
  },

};
</script>

<style lang="scss" scoped>

.node-search {
  margin-top: 10px;
  left: 210px;
  position: absolute;
}
</style>
