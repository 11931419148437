<template>
    <div>
      <edModal
            :confirmLoading="confirmLoading"
            :visible="visible"
            @handleOk="modalSearch"
            @cancelModal="modalSearchModal"
        >
          <template #dynamic-content>
            <a-tabs default-active-key="1" @change="callback">
              <a-tab-pane key="1" tab="Logical Search">
                <div style="display: flex;" >
                    <a-input v-model="searchQueryOpenAI" ref="logicalSearch" placeholder="Enter Topic Name, e.g. Blockchain or Digital Marketing" focus/>
                    &nbsp;&nbsp;
                    <a-select v-model="selectedLogicalSearchMode" style="width: 120px">
                    <a-select-option value="default">
                        Default
                    </a-select-option>
                    <a-select-option value="experiment">
                        Experiment
                    </a-select-option>
                    </a-select>
                </div>
              </a-tab-pane>
              <a-tab-pane key="2" tab="Summarize Text" force-render>
                <a-textarea
                  v-model="paraGraph"
                  placeholder="write paragraph(In less than 7000 words)"
                  :auto-size="{ minRows: 3, maxRows: 5 }"
                   @input="validateTextarea"
                />
                <div v-if="!isValid" class="error-message">{{ errorMsg }}</div>
              </a-tab-pane>
            </a-tabs>
          </template>
        </edModal>

    </div>
</template>

<script>
import edModal from '@/common/components/ed-forms/ed-modal.vue';
// eslint-disable-next-line global-require

export default {
  props: ['selectedNodesG6'],
  data() {
    return {
      searchQueryOpenAI: '',
      selectedLogicalSearchMode: 'default',
      modalTitle: 'Dot Connector(e.g. useful for understanding flows)',
      confirmLoading: false,
      visible: false,

      paraGraph: '',
      activeTabKey: '1',

      isValid: true,
      errorMsg: '',
    };
  },
  components: {
    edModal,
  },
  mounted() {
    document.addEventListener('keydown', this.handleKeyCombination);
    this.$root.$on('logicalSearch', this.logicalSearch);
  },
  methods: {
    validateTextarea() {
      const wordCount = this.paraGraph.split(/\s+/).length;
      if (wordCount > 7000) {
        this.isValid = false;
        this.errorMsg = 'The paragraph should not exceed 7000 words.';
      } else {
        this.isValid = true;
        this.errorMsg = '';
      }
    },

    callback(key) {
      this.activeTabKey = key;
    },

    modalSearchModal() {
      this.visible = false;
    },
    async modalSearch() {
      if (this.activeTabKey === '1') {
        this.confirmLoading = true;
        await this.$store.dispatch('gmodule/addLogicalData', {
          query: this.searchQueryOpenAI,
          mode: this.selectedLogicalSearchMode,
          selectedNodes: this.selectedNodesG6,
        });
        this.confirmLoading = false;
        this.visible = false;
      } else if (this.activeTabKey === '2') {
        if (this.isValid) {
          this.confirmLoading = true;
          await this.$store.dispatch('gmodule/pargraphicalData', {
            query: this.paraGraph,
            mode: 'paragraph',
            selectedNodes: this.selectedNodesG6,
          });
          this.confirmLoading = false;
          this.visible = false;
        }
      }
    },

    handleKeyCombination(event) {
      // Check if 'L' key is pressed along with 'Alt' and 'Shift'
      if (event.altKey && event.shiftKey && event.keyCode === 76) {
        console.log('Alt + Shift + L combination pressed!');
        this.visible = !this.visible;
        if (this.visible) {
          this.$nextTick(() => this.$refs.logicalSearch.focus());
        }
        // Your logic here...
      }
    },

    logicalSearch() {
      this.visible = !this.visible;
      if (this.visible) {
        this.$nextTick(() => this.$refs.logicalSearch.focus());
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.error-message {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}
</style>
